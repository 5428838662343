import React from "react"
import Layout from "../components/_App/layout"
import SEO from "../components/_App/seo"
import { Link } from "gatsby";
import img from '../images/404.png';

const NotFoundPage = () => (
    <Layout>
        <SEO title="404: Not found" />

        <div className="error-area">
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="error-content">
                            <h1 style={{ fontWeight: 'bold', fontFamily: `url(${require('../assets/fonts/Nexa.ttf')})`, fontSize: 80 }}>404</h1>
                            <h3  style={{ fontWeight: 'bold', fontFamily: `url(${require('../assets/fonts/Nexa.ttf')})`, fontSize: 30, textTransform: 'uppercase' }}>Non riusciamo proprio a trovare quello che stai cercando</h3>

                            <img src={img} style={{ position: 'absolute', left: 0, width: '100vw', height: 'auto', objectFit: 'contain', marginTop: 150, marginBottom: 50 }} />

                            <Link to="/" className="btn btn-primary" style={{ marginTop: 50 }} > 
                                Torna alla home
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
